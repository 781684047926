import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactGA from 'react-ga'

import Routes from './Routes'
import Toast from './components/Loadboard/Searches/Toast'

import { DarkModeProvider } from './context/DarkModeContext'
import { getCurrentUser } from './services/authService'

import TelegramGroupConnect from './components/TelegramGroupConnect/TelegramGroupConnect'

const queryClient = new QueryClient()

function App() {
	const [user, setUser] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [urlData, setUrlData] = useState(null)
	const [toastShow, setToastShow] = useState(false)
	const [toastMessage, setToastMessage] = useState('')

	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const token = urlParams.get('token')
	const chatId = urlParams.get('chatId')

	useEffect(() => {
		const fetchUserAndHandleTelegramLink = async () => {
			if (token && chatId) {
				try {
					const url = '/api/user/link-telegram-group'

					const response = await axios.get(url, { params: { token, chatId } })

					console.log('response', response?.data?.groupName)

					setIsModalOpen(true)
					setUrlData({ token, chatId, groupName: response.data.groupName })
				} catch (error) {
					const user = getCurrentUser()

					console.log('Error linking Telegram group:', error)

					if (user) {
						let errorMessage = 'Error linking Telegram group'
						if (error?.response?.data?.message) {
							errorMessage = error.response.data.message
						}

						setToastShow(true)
						setToastMessage(errorMessage)

						setTimeout(() => {
							setToastShow(false)
						}, 5000)
					}
				}
			}
		}

		fetchUserAndHandleTelegramLink()
	}, [])

	useEffect(() => {
		const fetchUser = async () => {
			const user = getCurrentUser()

			if (user) {
				setUser(user)
				window.clarity('identify', user?.email)
			}
		}

		fetchUser()
	}, [])

	useEffect(() => {
		ReactGA.initialize('UA-180688059-1')
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<DarkModeProvider>
				<BrowserRouter>
					<ToastContainer />
					<Routes user={user} />

					<TelegramGroupConnect
						isModalOpen={isModalOpen}
						closeModal={() => setIsModalOpen(false)}
						urlData={urlData}
					/>

					{toastShow && <Toast message={toastMessage} error={true} />}
				</BrowserRouter>
			</DarkModeProvider>
		</QueryClientProvider>
	)
}

export default App
