/*global chrome*/

import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { TextField } from './Fields'
import { Logo } from '../LandingPage/Logo'
import { SlimLayout } from './SlimLayout'

import { login } from '../../services/authService'
import { baseURL } from '../../config'
import './authPages.css'

import googleIcon from '../../media/icons/google.svg'

export const metadata = {
	title: 'Sign In',
}

// const sendTokenToChromeExtension = (extensionId, token) => {
// 	if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
// 		chrome.runtime.sendMessage(extensionId, token)
// 	} else {
// 		console.log('Chrome extension messaging API not available.')
// 	}
// }

const sendTokenToChromeExtension = (extensionId, token) => {
	if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
		try {
			chrome.runtime.sendMessage(extensionId, token, response => {
				if (chrome.runtime.lastError) {
					console.log(
						'Chrome extension is not installed or not active:',
						chrome.runtime.lastError.message
					)
				} else {
					console.log('response', response)
				}
			})
		} catch (error) {
			console.error('Error sending message to Chrome extension:', error)
		}
	} else {
		console.log('Chrome extension messaging API not available.')
	}
}

const productionExtensionId = 'ihcgicdogclbieclnldfjmlglkolankb'
const developmentExtensionId = 'omgobnjbempcolkjnfphklponcmlooko'
const extensionId = productionExtensionId

export default function Login({
	sourceExtension,
	setSourceExtension,
	windowOpender,
}) {
	let location = useLocation()
	const navigate = useNavigate()

	const authUrl = !baseURL ? '' : baseURL
	let googleAuthUrl = `${authUrl}/auth/google`

	const [loading, setLoading] = useState(false)

	const [state, setState] = useState({
		email: '',
		password: '',
		errorMsg: null,
		loading: false,
		typeChanged: false,
		displayGoogleBtn: true,
		errorText: '',
	})

	useEffect(() => {
		const handlePostMessage = event => {
			const token = event.data.token

			const params = new URLSearchParams(location.search)

			if (sourceExtension && windowOpender) {
				if (!token) return

				windowOpender?.postMessage(
					{
						source: 'loadfetcherLogin',
						token: token,
					},
					'*'
				)

				setTimeout(() => {
					localStorage.clear()

					window.close()
				}, 1000)
			} else if (params.get('source') === 'extension' && window.opener) {
				if (!token) return

				setSourceExtension(true)

				window.opener?.postMessage(
					{
						source: 'loadfetcherLogin',
						token: token,
					},
					'*'
				)

				setTimeout(() => {
					localStorage.clear()

					window.close()
				}, 1000)
			} else {
				token && localStorage.setItem('jwtToken', token)
				event.data.email && localStorage.setItem('email', event.data.email)

				sendTokenToChromeExtension(extensionId, {
					token,
				})

				const user = token && jwtDecode(token)
				const trial_end = new Date(user?.trial_end)
				const period_end = new Date(user?.period_end)

				const now = new Date()
				const isExpired = period_end < now || trial_end < now

				if (token && event.data.email && !isExpired)
					window.location = '/loadboard'
				else if (isExpired) window.location = '/plans'
			}
		}

		window.addEventListener('message', handlePostMessage)

		return () => {
			window.removeEventListener('message', handlePostMessage)
		}
	}, [location])

	const onChange = e => {
		e.persist()
		setState(prevState => ({
			...prevState,
			loading: false,
			[e.target.name]: e.target.value,
			errorText: '',
		}))
	}

	const onSubmit = async e => {
		e.preventDefault()

		setState(prevState => ({
			...prevState,
			loading: true,
		}))

		try {
			setLoading(true)
			await login(state, location, navigate, sourceExtension, windowOpender)
			setLoading(false)
		} catch (err) {
			console.log('login error', err)

			let errorText = err?.response?.data?.error
				? err.response.data.error
				: 'An error occurred while logging in.'

			setState(prevState => ({
				...prevState,
				loading: false,
				errorText: errorText,
			}))

			setLoading(false)
		}
	}

	return (
		<SlimLayout>
			{!sourceExtension && (
				<div className="flex">
					<>
						<Link to="/" aria-label="Home">
							<Logo
								color={'text-gray-600 text-lg'}
								height={'45px'}
								width={'45px'}
							/>
						</Link>
					</>
				</div>
			)}

			<h2 className="mt-3 mb-4 text-lg font-semibold text-gray-900">
				Sign in to your account
			</h2>

			<button
				className="google-button w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
				type="button"
				onClick={() => {
					const w = 500
					const h = 600
					const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
					const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

					window.open(
						googleAuthUrl,
						'Google Sign-In',
						`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
					)
				}}
			>
				<img
					src={googleIcon}
					alt="Google"
					style={{ width: '20px', height: '20px' }}
				/>

				<p className="btn-text">Continue with Google</p>
			</button>

			<div className="divider text-gray-800">OR</div>

			<form onSubmit={onSubmit} className="mt-1 grid grid-cols-1 gap-y-8">
				{/* Error message display */}
				{state.errorText && (
					<div className="error-message text-red-500 mt-2">
						{state.errorText}
					</div>
				)}

				<TextField
					className="col-span-full -my-2"
					label="Email address"
					id="email"
					name="email"
					type="email"
					autoComplete="email"
					required
					onChange={onChange}
				/>

				<TextField
					className="col-span-full -my-4"
					label="Password"
					id="password"
					name="password"
					type="password"
					autoComplete="current-password"
					required
					onChange={onChange}
				/>

				<div>
					<button
						disabled={loading}
						type="submit"
						className="submit-button group inline-flex items-center justify-center py-2 px-4 text-sm font-semibold bg-blue-600 text-white hover:text-gray-900 hover:bg-gray-50 active:bg-gray-100 focus:outline-none focus-visible:outline-blue-600 rounded-full w-full"
					>
						{loading && (
							<span className="loading loading-spinner loading-md mr-2"></span>
						)}

						<span>
							Sign in <span aria-hidden="true">&rarr;</span>
						</span>
					</button>
				</div>

				{/* Links for Password Reset and Signup */}
				<div className="col-span-full flex justify-between items-center">
					<p className="text-sm text-gray-700">
						Don’t have an account?{' '}
						<Link
							to={`/account/signup${
								sourceExtension ? '?source=extension' : ''
							}`}
							className="font-medium text-blue-600 hover:underline"
						>
							Sign up
						</Link>
					</p>

					<Link
						to="/password-reset-request"
						className="text-sm text-blue-600 hover:underline"
					>
						Forgot password?
					</Link>
				</div>
			</form>
		</SlimLayout>
	)
}
