import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { LRUCache } from 'lru-cache'
import axios from 'axios'
import {
	Bars3Icon,
	SunIcon,
	MoonIcon,
	ArrowUpCircleIcon,
} from '@heroicons/react/20/solid'

import Sidebar from '../Sidebar'
import LoadBoardFilters from './LoadBoardFilters'
import Load from './Load'
import Spinner from './Spinner'
import SortyByLoads from './SortyByLoads'
import ToggleFilter from './ToggleFilter'
import LoggedOutModal from '../AuthPages/LoggedOutModal'
import SelectedFilters from './SelectedFilters'

import { Logo } from '../LandingPage/Logo'

import './loadboard.css'
import 'daisyui/dist/full.css'

import { checkNewLoad } from './loadboardHelpers'
import { useDarkMode } from '../../context/DarkModeContext'
import { nearCity } from '../../services/httpService'
import { getCurrentUser } from '../../services/authService'

import { baseURL } from '../../config'

import UpgradeSection from './UpgradeSection'

const initialFilters = {
	distance: '',
	maxdistance: '',
	dropLat: '',
	dropLng: '',
	dropCity: '',
	dropState: '',
	dropStateName: '',
	dropoffMaxDist: 100,
	pickupCity: '',
	pickupState: '',
	pickupStateName: '',
	pickupLat: '',
	pickupLng: '',
	pickupMaxDist: 50,
	equipType: '',
	pricePerMile: null,
	pricePerMileMin: '',
	price: '',
	payoutMin: '',
	sourceType: null,
	excludeBroker: null,
	excludeOrIncludeBrokers: true,
	paginateSize: 1,
	startDateNearest: null,
	deadHeadShortest: null,
	loadsNewest: true,
	stopCount: 0,
	weight: 0,
	limited: false,
	ignoreLoadOut: false,
	lastSeen: 30,
	applySavedFilters: false,
	bobtail: false,
	origin: '',
	destination: '',
	sortBy: 'newest',
}

const loadCache = new LRUCache({ max: 300 })

export default function Loadboard() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [currentPage, setCurrentPage] = useState(1)
	const itemsPerPage = 10

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [isAudioOn, setAudioOn] = useState(false)
	const [showScrollTop, setShowScrollTop] = useState(false)
	const [paymentRequired, setPaymentRequired] = useState(false)
	const [invoiceLoading, setInvoiceLoading] = useState(false)

	const [filters, setFilters] = useState({
		distance: '',
		maxdistance: '',
		dropLat: '',
		dropLng: '',
		dropCity: '',
		dropState: '',
		dropStateName: '',
		dropoffMaxDist: 100,
		pickupCity: '',
		pickupState: '',
		pickupStateName: '',
		pickupLat: '',
		pickupLng: '',
		pickupMaxDist: 50,
		equipType: '',
		pricePerMile: null,
		price: '',
		payoutMin: '',
		sourceType: null,
		excludeBroker: null,
		excludeOrIncludeBrokers: true,
		paginateSize: 1,
		startDateNearest: null,
		deadHeadShortest: null,
		loadsNewest: true,
		stopCount: 0,
		weight: 0,
		limited: false,
		ignoreLoadOut: false,
		lastSeen: 30,
		applySavedFilters: false,
		bobtail: false,
		origin: '',
		destination: '',
		sortBy: 'newest',
	})

	const { data, isLoading, isError, error, refetch, isFetching } = useQuery(
		['loads', { ...filters, page: currentPage, itemsPerPage }],
		() =>
			nearCity({
				...filters,
				number: currentPage,
				size: itemsPerPage,
				setPaymentRequired,
			}),
		{
			refetchOnWindowFocus: false,
			onSuccess: data => checkNewLoad(data, loadCache, isAudioOn),
		}
	)

	const handlePrevPage = () => {
		setCurrentPage(prevPage => prevPage - 1)
		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: prevFilters.paginateSize - 1,
		})) // Decrement the paginateSize
	}

	const handleNextPage = () => {
		setCurrentPage(prevPage => prevPage + 1)
		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: prevFilters.paginateSize + 1,
		})) // Increment the paginateSize
	}

	const handleScroll = () => {
		setShowScrollTop(window.scrollY > 300)
	}

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const handleSort = e => {
		const { name, value } = e.target

		setFilters({
			...filters,
			paginateSize: 1,
			[name]: value,
		})
	}

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const resetFilters = () => {
		setFilters(initialFilters)
	}

	const removeFilter = key => {
		setFilters(prevFilters => ({
			...prevFilters,
			[key]: initialFilters[key],
		}))
	}

	useEffect(() => {
		refetch()
		setPaymentRequired(false)
	}, [filters, currentPage])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	const user = getCurrentUser()

	useEffect(() => {
		const fetchInvoice = async () => {
			try {
				const clientEmail = user?.email

				const location = window.location.pathname.includes('/loadboard')

				if (!clientEmail || !location) return

				// if (paymentRequired)
				setInvoiceLoading(true)

				const invoiceData = await axios.get(
					`${baseURL}/api/stripe/checkInvoice`,
					{
						params: { customerEmail: clientEmail },
					}
				)

				const dateFromServer = new Date(invoiceData?.data?.trialEndTime)
				const currentDate = new Date()

				const isDateInPast = dateFromServer < currentDate

				if (invoiceData?.data?.payed) {
					setPaymentRequired(false)

					if (paymentRequired) refetch()

					setInvoiceLoading(false)
					return
				}

				if (!isDateInPast) {
					setPaymentRequired(false)

					if (paymentRequired) refetch()

					setInvoiceLoading(false)
					return
				}

				setInvoiceLoading(false)
			} catch (error) {
				console.log('error', error)
				setInvoiceLoading(false)
			}
		}

		fetchInvoice()
	}, [])

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				{/* <div> */}
				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						<p className="text-sky-500 font-light italic tracking-wide mr-3">
							{user.email}
						</p>
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main className={isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}>
					<LoadBoardFilters
						isDarkMode={isDarkMode}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
						resetFilters={resetFilters}
						isAudioOn={isAudioOn}
						setAudioOn={setAudioOn}
					/>

					<div
						className={`${
							isDarkMode ? 'bg-dark-color' : 'bg-light-gray'
						} max-w-screen-xl mx-auto top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 px-0 shadow-sm sm:px-6 lg:px-0`}
					>
						<div className="flex flex-grow">
							<SelectedFilters
								filters={filters}
								initialFilters={initialFilters}
								removeFilter={removeFilter}
							/>
						</div>

						<div className="flex items-center">
							<label
								className={`${
									isDarkMode ? 'text-white' : 'text-black'
								} mx-2 whitespace-nowrap font-bold text-sm mr-2`}
							>
								{filters.limited ? 'Hide ' : 'Show '} Old Loads
							</label>
							<ToggleFilter
								show={filters.limited}
								setShow={() => {
									setFilters({
										...filters,
										limited: !filters.limited,
										paginateSize: 1,
									})
								}}
							/>
						</div>

						<div className="shadow-sm block rounded-xs text-xs p-0">
							<SortyByLoads
								onChange={handleSort}
								value={filters.sortBy}
								name="sortBy"
								isDarkMode={isDarkMode}
							/>
						</div>
					</div>

					<div className="mb-5">
						{paymentRequired ? (
							<UpgradeSection isDarkMode={isDarkMode} />
						) : isLoading || isFetching || invoiceLoading ? (
							<Spinner isDarkMode={isDarkMode} />
						) : Array.isArray(data?.loads) && data?.loads?.length ? (
							data?.loads?.map(load => <Load key={load?._id} load={load} />)
						) : (
							<div
								className={`${
									isDarkMode ? 'text-white' : 'text-black'
								} pt-24 flex justify-center items-center text-3xl font-medium text-slate-800`}
							>
								No matching data
							</div>
						)}
					</div>
				</main>

				{/* </div> */}
			</div>

			{/* Pagination controls */}
			{!isLoading &&
				!isFetching &&
				!invoiceLoading &&
				Array.isArray(data?.loads) &&
				(data?.loads?.length >= 20 || filters.paginateSize > 1) && (
					<div className="flex justify-center mb-8 mt-8">
						<div
							className={`join flex justify-center ${
								isDarkMode
									? 'text-white bg-dark-mode-color'
									: 'text-black bg-light-mode-color'
							}`}
						>
							<button
								onClick={handlePrevPage}
								disabled={filters.paginateSize === 1}
								className="join-item btn"
							>
								«
							</button>
							<button className="join-item btn">
								Page {filters.paginateSize}
							</button>
							<button
								onClick={handleNextPage}
								disabled={
									filters.paginateSize * itemsPerPage >=
									(data?.totalResults || 0)
								}
								className="join-item btn"
							>
								»
							</button>
						</div>
					</div>
				)}

			{/* {showScrollTop && (
				<ArrowUpCircleIcon
					onClick={scrollToTop}
					className="h-16 w-16 cursor-pointer"
					style={{
						position: 'fixed',
						bottom: '20px',
						right: '20px',
						backgroundColor: `${isDarkMode ? '#f5f5f7' : '#1c2126'}`,
						padding: '10px',
						borderRadius: '50%',
						border: 'none',
						cursor: 'pointer',
						zIndex: 999,
					}}
				/>
			)} */}

			<LoggedOutModal />
		</>
	)
}
