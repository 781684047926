import { useState, useEffect } from 'react'
import Axios from 'axios'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../../Sidebar'
import { Logo } from '../../LandingPage/Logo'

import { useDarkMode } from '../../../context/DarkModeContext'

import { getCurrentUser } from '../../../services/authService'
import { baseURL } from '../../../config'

const timeAgo = date => {
	const seconds = Math.floor((new Date() - new Date(date)) / 1000)
	const intervals = [
		{ label: 'day', seconds: 86400 },
		{ label: 'hour', seconds: 3600 },
		{ label: 'minute', seconds: 60 },
	]

	for (const interval of intervals) {
		const count = Math.floor(seconds / interval.seconds)
		if (count > 0) {
			return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
		}
	}

	return 'just now'
}

export default function SearchIndex() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [searches, setSearches] = useState([])

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const handleDeleteSearch = async id => {
		console.log('search', id)

		try {
			const result = await Axios.delete(`${baseURL}/api/scraper/${id}`)

			console.log('result', result)

			if (result.status === 200) {
				// Remove the deleted search from the state by filtering it out
				setSearches(searches.filter(search => search._id !== id))
			}
		} catch (error) {
			console.log(error)
		}
	}

	const deleteAllStopped = async () => {
		try {
			const user = getCurrentUser()

			const result = await Axios.delete(
				`${baseURL}/api/scraper/deleteAllStopped/${user.id}`
			)

			if (result.status === 200) {
				setSearches(searches.filter(search => search.isSearchActive))
			}
		} catch (error) {
			console.log(error)
		}
	}

	// chanes to searches
	useEffect(() => {
		const fetchSearches = async () => {
			try {
				const user = getCurrentUser()

				const data = await Axios.get(
					`${baseURL}/api/search/${user.id}?limit=${20}&skip=${0}`
				)

				setSearches(data.data.searches)
			} catch (error) {
				console.log('error', error)
				setSearches([])
			}
		}

		fetchSearches()

		return () => {
			setSearches([])
		}
	}, [])

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main
					className={
						isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'
					}
				>
					<div
						className={`container mx-auto py-4 ${
							isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
						}`}
					>
						<button className="btn btn-error" onClick={deleteAllStopped}>
							Delete All stopped
						</button>

						{Array.isArray(searches) &&
							searches.map(search => {
								return (
									<div
										key={search._id}
										className={`grid grid-cols-4 gap-4 p-4 border border-gray-300 m-2 ${
											isDarkMode ? 'bg-gray-700' : 'bg-white'
										} shadow-md rounded-lg`}
									>
										<span className="text-lg font-semibold">
											<p>{search.pickUpLocation}</p>
											<p>{search?.unitType || 'Power Only'}</p>
										</span>
										<span className="text-lg font-semibold">
											{search?.loadBoards?.map(loadBoard => (
												<div key={loadBoard}>
													<p className="text-sm">{loadBoard}</p>
												</div>
											))}
										</span>
										<span className={`text-lg font-semibold `}>
											<p
												className={`${
													search.isSearchActive
														? 'text-green-500'
														: 'text-red-500'
												}`}
											>
												{search.isSearchActive ? 'running' : 'stopped'}
											</p>
											<p>{timeAgo(search.createdAt)}</p>
											<p>{search?.loadCount} loads found</p>
										</span>
										<span className="text-lg font-semibold">
											<button
												onClick={() => handleDeleteSearch(search._id)}
												className="btn btn-error"
											>
												Delete
											</button>
										</span>
									</div>
								)
							})}
					</div>
				</main>
			</div>
		</>
	)
}
