import React from 'react'
import LineChart from '../Charts/Linechart1'

// Import utilities
import {
	tailwindConfig,
	hexToRGB,
	percDiff,
	formatThousands,
} from '../../utils'

function AnalyticsCard02({ analytics, prevAnalytics }) {
	const {
		totalSum: currSum,
		totalCount: currCount,
		totalAmazonCount: amzCount,
		totalCoyoteCount: cytCount,
		totalDATCount: datCount,
		// totalEmergeCount: emrgCount,
		totalJBHuntCount: jbhCount,
		totalTQLCount: tqlCount,
	} = analytics || {}

	const {
		totalSum: prevSum,
		totalCount: prevCount,
		totalAmazonCount: prevAmzCount,
		totalCoyoteCount: prevCytCount,
		totalDATCount: prevDatCount,
		// totalEmergeCount: prevEmrgCount,
		totalJBHuntCount: prevJbhCount,
		totalTQLCount: prevTqlCount,
	} = prevAnalytics || {}

	const totalSumDiff = percDiff(currSum, prevSum)
	const sumUpOrDown = currSum >= prevSum ? true : false

	const totalCountDiff = percDiff(currCount, prevCount)
	const countUpOrDown = currCount >= prevCount ? true : false

	const amzCountDiff = percDiff(amzCount, prevAmzCount)
	const amzCountUpOrDown = amzCount >= prevAmzCount ? true : false

	const cytCountDiff = percDiff(cytCount, prevCytCount)
	const cytCountUpOrDown = cytCount >= prevCytCount ? true : false

	const datCountDiff = percDiff(datCount, prevDatCount)
	const datCountUpOrDown = datCount >= prevDatCount ? true : false

	const jbHCountDiff = percDiff(jbhCount, prevJbhCount)
	const jbHCountUpOrDown = jbhCount >= prevJbhCount ? true : false

	const tqlCountDiff = percDiff(tqlCount, prevTqlCount)
	const tqlCountUpOrDown = tqlCount >= prevTqlCount ? true : false

	// const emrgCountDiff = percDiff(emrgCount, prevEmrgCount)
	// const emrgCountUpOrDown = emrgCount >= prevEmrgCount ? true : false

	const chartData = {
		labels: [
			'12-01-2020',
			'01-01-2021',
			'02-01-2021',
			'03-01-2021',
			'04-01-2021',
			'05-01-2021',
			'06-01-2021',
			'07-01-2021',
			'08-01-2021',
			'09-01-2021',
			'10-01-2021',
			'11-01-2021',
		],
		datasets: [
			// Indigo line
			{
				data: [
					732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391, 192,
					154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532,
				],
				fill: true,
				backgroundColor: `rgba(${hexToRGB(
					tailwindConfig().theme.colors.blue[500]
				)}, 0.08)`,
				borderColor: tailwindConfig().theme.colors.indigo[500],
				borderWidth: 2,
				tension: 0,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
				clip: 20,
			},
		],
	}

	return (
		<div className="flex flex-col col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
			<header className="px-5 py-4 border-b border-gray-100">
				<h2 className="font-semibold text-gray-800">Last 24 Hours</h2>
			</header>
			{/* Card content */}
			<div className="flex flex-col h-full">
				{/* Live visitors number */}
				<div className="px-5 py-3">
					<div className="flex items-stretch">
						{/* Vistors number */}
						<div className="mr-6">
							<div className="text-3xl font-bold text-gray-800 mr-2">
								${formatThousands(analytics?.totalSum || 0)}
								<span
									className={`font-light ml-2 text-base ${
										sumUpOrDown ? 'text-green-500' : 'text-red-500'
									}`}
								>
									{sumUpOrDown ? '+' : '-'}
									{Math.abs(totalSumDiff || 0)}%
								</span>
							</div>
							<div className="text-sm text-gray-500">Total Sum</div>
						</div>
						<div>
							<div className="text-3xl font-bold text-gray-800 mr-2">
								{formatThousands(analytics?.totalCount || 0)}
								<span
									className={`font-light ml-2 text-base ${
										countUpOrDown ? 'text-green-500' : 'text-red-500'
									}`}
								>
									{countUpOrDown ? '+' : '-'}
									{Math.abs(totalCountDiff)}%
								</span>
							</div>
							<div className="text-sm text-gray-500">Total Count</div>
						</div>
					</div>
				</div>

				{/* Chart built with Chart.js 3 */}
				<div>
					{/* Change the height attribute to adjust the chart height */}
					<LineChart data={chartData} width={389} height={70} />
				</div>

				{/* Table */}
				<div className="grow px-5 pt-3 pb-1">
					<div className="overflow-x-auto">
						<table className="table-auto w-full">
							{/* Table header */}
							<thead className="text-xs uppercase text-gray-400">
								<tr>
									<th className="py-1">
										<div className="font-semibold text-left">Loadboards </div>
									</th>
									<th className="py-1">
										<div className="font-semibold text-right">Count</div>
									</th>
								</tr>
							</thead>
							{/* Table body */}
							<tbody className="text-sm divide-y divide-gray-100">
								<tr>
									<td className="py-1 px-6">
										<div className="text-left text-base">DAT</div>
									</td>
									<td className="py-1 px-6">
										<div className="font-medium text-right text-black">
											{formatThousands(analytics?.totalDATCount || 0)}
											<span
												className={`text-xs ml-2 ${
													datCountUpOrDown ? 'text-green-500' : 'text-red-500'
												}`}
											>
												{datCountUpOrDown ? '+' : '-'} {Math.abs(datCountDiff)}%
											</span>
										</div>
									</td>
								</tr>
								{/* Row */}
								<tr>
									<td className="py-1 px-6">
										<div className="text-left text-base">Amazon</div>
									</td>
									<td className="py-1 px-6">
										<div className="font-medium text-base text-right text-black">
											{formatThousands(analytics?.totalAmazonCount || 0)}
											<span
												className={`text-xs ml-2 ${
													amzCountUpOrDown ? 'text-green-500' : 'text-red-500'
												}`}
											>
												{amzCountUpOrDown ? '+' : '-'}{' '}
												{Math.abs(amzCountDiff || 0)}%
											</span>
										</div>
									</td>
								</tr>
								{/* Row */}
								<tr>
									<td className="py-1 px-6">
										<div className="text-left text-base mr-24">JB Hunt</div>
									</td>
									<td className="py-1 px-6">
										<div className="font-medium text-right text-black">
											{formatThousands(analytics?.totalJBHuntCount || 0)}
											<span
												className={`text-xs ml-2 ${
													jbHCountUpOrDown ? 'text-green-500' : 'text-red-500'
												}`}
											>
												{jbHCountUpOrDown ? '+' : '-'}{' '}
												{Math.abs(isNaN(jbHCountDiff) ? 0 : jbHCountDiff)}%
											</span>
										</div>
									</td>
								</tr>

								<tr>
									<td className="py-1 px-6">
										<div className="text-left text-base">TQL</div>
									</td>
									<td className="py-1 px-6">
										<div className="font-medium text-right text-black">
											{formatThousands(analytics?.totalTQLCount || 0)}

											<span
												className={`text-xs ml-2 ${
													tqlCountUpOrDown ? 'text-green-500' : 'text-red-500'
												}`}
											>
												{tqlCountUpOrDown ? '+' : '-'}{' '}
												{Math.abs(isNaN(tqlCountDiff) ? 0 : tqlCountDiff)}%
											</span>
										</div>
									</td>
								</tr>
								<tr>
									{/* <td className="py-1 px-6">
										<div className="text-left text-base">Emerge</div>
									</td> */}
									{/* <td className="py-1 px-6">
										<div className="font-medium text-right text-black">
											{formatThousands(analytics?.totalEmergeCount)}

											<span
												className={`text-xs ml-2 ${
													emrgCountUpOrDown ? 'text-green-500' : 'text-red-500'
												}`}
											>
												{emrgCountUpOrDown ? '+' : '-'}{' '}
												{Math.abs(emrgCountDiff)}%
											</span>
										</div>
									</td> */}
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AnalyticsCard02
