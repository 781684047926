import { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../Sidebar'
import LoggedOutModal from '../AuthPages/LoggedOutModal'
import { Logo } from '../LandingPage/Logo'

import { useDarkMode } from '../../context/DarkModeContext'

import styles from '../Loadboard/Searches/UserSearches.module.css'
import { baseURL } from '../../config'

const CancelModal = ({ isModalOpen, closeModal, onSubmit, loading }) => {
	return (
		<>
			<dialog className={`modal ${isModalOpen ? 'modal-open' : ''}`}>
				<form
					method="dialog"
					className={` modal-box ${styles['searches-modal-box-custom']}`}
				>
					<h2 className="font-bold text-lg text-amber-200">
						Cancel Membership
					</h2>
					<p className="text-lg pt-4 py-2 text-gray-200">
						Are you sure you want to cancel your membership?
					</p>

					<div className="modal-action">
						<button onClick={closeModal} className="btn">
							Close
						</button>

						<button onClick={onSubmit} className="btn btn-error">
							{loading && <span className="loading loading-spinner"></span>}
							Submit
						</button>
					</div>
				</form>
			</dialog>
		</>
	)
}

// fix here
export default function SettingsMain() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [invoiceData, setInvoiceData] = useState(null)
	const [user, setUser] = useState(null)
	const [isModalOpen, setCancelModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const handleCancel = () => {
		setCancelModalOpen(true)
	}

	const closeModal = () => {
		setCancelModalOpen(false)
	}

	const handleSubmitCancel = async () => {
		try {
			setLoading(true)
			const clientEmail = user?.email

			await axios.get(`${baseURL}/api/stripe/cancelSub`, {
				params: { customerEmail: clientEmail },
			})

			const invoiceData = await axios.get(
				`${baseURL}/api/stripe/checkInvoice`,
				{
					params: { customerEmail: clientEmail },
				}
			)

			if (invoiceData.data) {
				setInvoiceData(invoiceData.data)
			}

			setLoading(false)
			setCancelModalOpen(false)
		} catch (error) {
			console.error(error)
		}
	}

	const handleManageBilling = async () => {
		try {
			if (!user || !user.email) {
				console.error('User email is not available.')
				return
			}

			const response = await axios.get(
				`${baseURL}/api/stripe/customer-portal`,
				{
					params: { email: user.email },
				}
			)

			window.location.href = response.data
		} catch (error) {
			console.error('Error redirecting to Stripe Customer Portal', error)
		}
	}

	useEffect(() => {
		const fetchInvoice = async () => {
			try {
				setLoading(true)

				const jwt = localStorage.getItem('jwtToken')
				const userJwt = jwtDecode(jwt)
				setUser(userJwt)

				const clientEmail = userJwt?.email

				const invoiceData = await axios.get(
					`${baseURL}/api/stripe/checkInvoice`,
					{
						params: { customerEmail: clientEmail },
					}
				)

				if (invoiceData.data) {
					setInvoiceData(invoiceData.data)
				}

				setLoading(false)
			} catch (error) {
				console.log('error', error)
				setLoading(false)
			}
		}

		fetchInvoice()
	}, [])

	let dateFormat = null
	let formattedDate = null
	let payed = false
	let canceledSubscription = false

	if (invoiceData?.payed) {
		payed = true
		dateFormat = new Date(invoiceData?.period_end)
		formattedDate = dayjs(dateFormat).format('MMMM D, YYYY')
	} else {
		payed = false
		dateFormat = new Date(invoiceData?.trialEndTime)
		formattedDate = dayjs(dateFormat).format('MMMM D, YYYY')
	}

	const textStyle = { color: isDarkMode ? 'white' : 'black' }

	let paymentLabel

	if (!payed) {
		paymentLabel = 'Trial End'
	} else if (payed && invoiceData?.canceledSubscription) {
		paymentLabel = 'Plan Expires'
		canceledSubscription = true
	} else if (payed && !invoiceData?.canceledSubscription) {
		paymentLabel = 'Next Payment'
	}

	const paidAmount = invoiceData?.amount / 100

	const isYearly = invoiceData?.subscription?.type === 'yearly'
	const isMonthly = invoiceData?.subscription?.type === 'monthly'
	const subType = isYearly ? 'Yearly' : isMonthly ? 'Monthly' : ''

	const isTrial = invoiceData?.subscription?.isTrial
	const subPlan = isTrial ? 'Trial' : 'Full'

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main className={isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}>
					<div className={`${isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}`}>
						{loading ? (
							<h1
								style={{
									textAlign: 'center',
									fontSize: '24px',
									fontWeight: 'normal',
									// margin: '50px 0',
									color: isDarkMode ? 'white' : 'black',
								}}
							>
								Loading...
							</h1>
						) : (
							<div
								className="rounded-md p-6 text-white shadow-lg max-w-lg mx-auto"
								style={{ maxWidth: '800px' }}
							>
								{/* Membership Information Card */}
								<h2 style={textStyle} className="text-lg font-semibold mb-4">
									Your Membership
								</h2>
								<div className="flex justify-between mb-2">
									<p style={textStyle} className="font-semibold">
										{canceledSubscription ? 'Cancelled' : 'Active'}{' '}
										{!payed ? 'Trial' : 'Subscription'}
									</p>
									<p style={textStyle} className="font-semibold">
										{subType} Payment
									</p>
									<p style={textStyle} className="font-semibold">
										{paymentLabel}
									</p>
								</div>
								<div className="flex justify-between mb-4 ml-2">
									<p style={textStyle} className="text-sm">
										{subPlan} Plan
									</p>
									<p style={textStyle} className="text-sm">
										{payed ? `$${paidAmount?.toFixed(2)}` : '$0.00'}
									</p>
									<p style={textStyle} className="text-sm">
										{formattedDate}
									</p>
								</div>
								{/* {payed && !canceledSubscription && ( */}
								{/* <> */}
								{/* <div className="flex justify-end">
											<button
												onClick={handleCancel}
												className="bg-gray-800 rounded p-2 text-gray-200"
											>
												Cancel Membership
											</button>
										</div> */}
								{/* </> */}
								{/* )} */}
								{/* Membership Information Card End */}

								<button
									onClick={handleManageBilling}
									className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
								>
									Manage Billing
								</button>
							</div>
						)}
					</div>
				</main>
			</div>

			<CancelModal
				isModalOpen={isModalOpen}
				closeModal={closeModal}
				onSubmit={handleSubmitCancel}
				loading={loading}
			/>

			<LoggedOutModal />
		</>
	)
}
