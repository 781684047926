import React from 'react'

import './authPages.css'

// ExclamationTriangleIcon

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

function LoggedOutModa() {
	const clickHandler = () => {
		localStorage.removeItem('jwtToken')
		window.location.href = '/account/login'
	}
	return (
		<>
			<dialog id="modalForOtherAccountLoggedIn" className="modal">
				<form method="dialog" className={`modal-box searches-modal-box-custom`}>
					<div className="flex items-center">
						<ExclamationTriangleIcon className="text-red-600 w-7 h-7 mr-2" />
						<h2 className="font-bold text-lg text-gray-200">
							Account Accessed from Another Device!
						</h2>
					</div>
					<p className="text-lg pt-4 py-2 text-gray-200">
						It appears that your account has been accessed from another device.
						This could be you logging in from a different location or an
						unauthorized access.
					</p>

					<div className="modal-action">
						<button onClick={clickHandler} className="btn btn-info">
							Login
						</button>
					</div>
				</form>
			</dialog>
		</>
	)
}

export default LoggedOutModa
