import { useState } from 'react'
import { Switch } from '@headlessui/react'

import { updateSearchNotification } from '../../../services/httpService'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NotificationToggle({ search }) {
	const [enabled, setEnabled] = useState(search.isNotification || false)

	const handleSwitch = async value => {
		await updateSearchNotification(search._id, value)
		setEnabled(value)
	}

	return (
		<Switch.Group as="div" className="flex items-center">
			<Switch.Label as="span" className="mr-3 text-sm">
				<span className="font-medium text-gray-100">Get notifications</span>
			</Switch.Label>

			<Switch
				checked={enabled}
				onChange={handleSwitch}
				style={{ backgroundColor: enabled ? '#22b0a5' : '#c6d4e2' }} // Set the color here
				className={classNames(
					'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2'
				)}
			>
				<span
					aria-hidden="true"
					className={classNames(
						enabled ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
					)}
				/>
			</Switch>
		</Switch.Group>
	)
}
