import React from 'react'

const capitalName = str => {
	return str
		?.toLowerCase()
		?.split(' ')
		?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		?.join(' ')
}

const SelectedFilters = ({ filters, initialFilters, removeFilter }) => {
	const keyNameMapping = {
		distance: 'min distance',
		equipType: 'equipment',
		stopCount: 'max stops',
		weight: 'max weight',
		ignoreLoadOut: 'loadout',
	}

	const getDisplayName = (key, value) => {
		const displayName = keyNameMapping[key] || key
		const formattedValue = key === 'equipType' ? capitalName(value) : value
		return `${displayName}: ${formattedValue}`
	}

	const renderLocation = type => {
		const cityKey = type === 'origin' ? 'pickupCity' : 'dropCity'
		const stateKey = type === 'origin' ? 'pickupState' : 'dropState'
		const label = type === 'origin' ? 'Origin' : 'Destination'

		const city = filters[cityKey]
		const state = filters[stateKey]
		if (
			city &&
			state &&
			(city !== initialFilters[cityKey] || state !== initialFilters[stateKey])
		) {
			return (
				<div className="flex m-2 bg-cyan-500 text-white rounded-full p-1 items-center">
					<span
						className="mx-1 -my-3"
						style={{ fontSize: '12px' }}
					>{`${label}: ${city}, ${state}`}</span>
					<button
						className="mx-0.5 text-gray-600 w-3 h-3 flex items-center justify-center font-semibold"
						onClick={() => removeFilter(label.toLowerCase())}
					>
						x
					</button>
				</div>
			)
		}
		return null
	}

	const renderSourceTypes = () => {
		const sourceTypes = filters['sourceType']
		if (sourceTypes && Array.isArray(sourceTypes) && sourceTypes.length > 0) {
			return sourceTypes.map((source, index) => (
				<div
					key={index}
					className="flex m-2 bg-cyan-500 text-white rounded-full p-1 items-center"
				>
					<span
						className="mx-1 -my-3"
						style={{ fontSize: '12px' }}
					>{`Source: ${source.label}`}</span>
					<button
						className="mx-0.5 text-gray-600 w-3 h-3 flex items-center justify-center font-semibold"
						onClick={() => removeFilter('sourceType', index)}
					>
						x
					</button>
				</div>
			))
		}
		return null
	}

	const renderExcludeBrokers = () => {
		const excludeBrokers = filters['excludeBroker']
		if (
			excludeBrokers &&
			Array.isArray(excludeBrokers) &&
			excludeBrokers.length > 0
		) {
			return excludeBrokers.map((broker, index) => (
				<div
					key={index}
					className="flex m-2 bg-cyan-500 text-white rounded-full p-1 items-center"
				>
					<span
						className="mx-1 -my-3"
						style={{ fontSize: '12px' }}
					>{`Broker: ${broker.label}`}</span>
					<button
						className="mx-0.5 text-gray-600 w-3 h-3 flex items-center justify-center font-semibold"
						onClick={() => removeFilter('excludeBroker', index)}
					>
						x
					</button>
				</div>
			))
		}
		return null
	}

	return (
		<div className="flex flex-wrap space-x-2">
			{renderLocation('origin')}
			{renderLocation('destination')}
			{renderSourceTypes()}
			{renderExcludeBrokers()}

			{Object.keys(filters).map(key => {
				if (
					filters[key] &&
					filters[key] !== initialFilters[key] &&
					![
						'pickupCity',
						'pickupState',
						'pickupLat',
						'pickupLng',
						'origin',
						'pickupStateName',
						'dropCity',
						'dropState',
						'dropLat',
						'dropLng',
						'dropStateName',
						'destination',
						'sourceType',
						'dropoffMaxDist',
						'pickupMaxDist',
						'limited',
						'excludeBroker',
						'excludeOrIncludeBrokers',
						'paginateSize',
						'sortBy',
					].includes(key)
				) {
					return (
						<div
							key={key}
							className="flex m-2 bg-cyan-500 text-white rounded-full p-1 items-center"
						>
							<span className="mx-1 -my-3" style={{ fontSize: '12px' }}>
								{getDisplayName(key, filters[key])}
							</span>
							<button
								className="mx-0.5 text-gray-600 w-3 h-3 flex items-center justify-center font-semibold"
								onClick={() => removeFilter(key)}
							>
								x
							</button>
						</div>
					)
				}
				return null
			})}
		</div>
	)
}

export default SelectedFilters
