import React, { useState, useEffect } from 'react'
import './checkbox.css'

const FilterCheckbox = ({ label, onToggle, filterName, initialState }) => {
	const [checked, setChecked] = useState(initialState)

	const handleToggle = () => {
		const newChecked = !checked
		setChecked(newChecked)
		onToggle(filterName, newChecked)
	}

	useEffect(() => {
		setChecked(initialState)
	}, [initialState])

	return (
		<div
			className={`flex justify-center items-center text-sm rounded-full h-10 w-auto checkbox-button ${
				checked ? 'checked' : ''
			}`}
			onClick={handleToggle}
		>
			<input
				type="checkbox"
				checked={checked}
				onChange={handleToggle}
				style={{ display: 'none' }}
			/>
			{checked ? `✔️ ${label}` : `${label}`}
		</div>
	)
}

export default FilterCheckbox
