import React, { useEffect, useState } from 'react'
import axios from 'axios'

function ExtensionAnalytics() {
	const [analyticsData, setAnalyticsData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userToken = localStorage.getItem('token')

				const response = await axios.get('/api/admin/extension/analytics', {
					headers: {
						authorization: userToken,
					},
				})

				setAnalyticsData(response.data.data)
			} catch (err) {
				setError(err)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])

	if (loading) return <div>Loading...</div>
	if (error) return <div>Error: {error.message}</div>

	return (
		<div>
			<h1 className="text-xl font-bold mb-4">Extension Analytics</h1>
			{analyticsData &&
				analyticsData?.map((user, index) => (
					<div key={index} className="mb-8">
						<h2 className="text-lg font-semibold mb-2">Email: {user._id}</h2>
						<table className="min-w-full border-collapse border border-gray-300">
							<thead>
								<tr>
									<th className="border border-gray-300 p-2">IP Address</th>
									<th className="border border-gray-300 p-2">Path</th>
									<th className="border border-gray-300 p-2">Count</th>
									<th className="border border-gray-300 p-2">
										Average Response Time
									</th>
									<th className="border border-gray-300 p-2">
										Last Request At
									</th>
								</tr>
							</thead>
							<tbody>
								{user.analytics.map((entry, entryIndex) => (
									<tr key={entryIndex}>
										<td className="border border-gray-300 p-2">
											{entry?.uniqueIPs?.join(', ')}
										</td>
										<td className="border border-gray-300 p-2">{entry.path}</td>

										<td className="border border-gray-300 p-2">
											{entry.count}
										</td>

										<td className="border border-gray-300 p-2">
											{entry.avgResponseTime.toFixed(2)}
										</td>

										<td className="border border-gray-300 p-2">
											{new Date(entry.lastRequestAt).toLocaleString()}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				))}
		</div>
	)
}

export default ExtensionAnalytics
