import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Tooltip } from 'react-tooltip'
import moment from 'moment-timezone'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import { useDarkMode } from '../../context/DarkModeContext'
import {
	colorBasedOnAge,
	formatLastSeenDate,
	ArrowDirection,
	dateDifference,
	truncateString,
} from './loadboardHelpers.js'

import './load.css'

import { loadAnalytics } from '../../services/httpService'

import LoadCollapse from './LoadCollapseContent/LoadCollapse'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.tz.guess()

export default function LoadCard({ load }) {
	const [isCollapsed, setIsCollapsed] = useState(true)
	const [hoverPickup, setHoverPickup] = useState(false)
	const [hoverDropoff, setHoverDropoff] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const [pickUpAnalytics, pickUpSetAnalytics] = useState({})
	const [dropOffAnalytics, dropOffSetAnalytics] = useState({})

	const { isDarkMode } = useDarkMode()

	const {
		pickup,
		dropOff,
		updatedAt,
		price,
		weight,
		distance,
		equipment,
		broker,
		createdAt,
	} = load

	const pickupCity = `${truncateString(pickup?.address?.city, 14)}, ${
		pickup.address.state
	}`
	// const puTZ = validateTimeZone(pickup?.timezone, dayjs)
	const puDate = pickup?.date_local

	// const pickupTime = dayjs(puDate).tz(puTZ).format('ddd MMM DD HH:mm z')

	let pickupTime = pickup?.timezone
		? moment(pickup?.date_local)
				.tz(pickup?.timezone)
				.format('HH:mm A, MM-DD-YY, z')
		: moment(pickup?.date_local).format('HH:mm A, MM-DD-YY z')

	const deliveryCity = `${truncateString(dropOff?.address?.city, 14)}, ${
		dropOff?.address?.state
	}`
	// const delTZ = validateTimeZone(dropOff?.timezone, dayjs)

	const delDate = dropOff?.date_local
	// const deliveryDate = dayjs(delDate).tz(delTZ).format('ddd MMM DD HH:mm z')

	let deliveryDate = dropOff?.timezone
		? moment(dropOff?.date_local)
				.tz(dropOff.timezone)
				.format('HH:mm A, MM-DD-YY, z')
		: moment(dropOff.date_local).format('HH:mm A, MM-DD-YY z')

	const source = broker.company
	const companyName = broker.companyName

	const loadStops = load?.loads?.length || 2

	const pricePerMile = price / distance

	const createdAtDiffInMins = dayjs().diff(dayjs(createdAt), 'minute')

	const styles = isDarkMode
		? {
				backgroundColor: createdAtDiffInMins < 5 ? '#313866' : '#22272e',
				color: '#adbac7',
				borderColor: '#444c56',
		  }
		: {
				backgroundColor: createdAtDiffInMins < 5 ? '#C8FFE0' : '#ffffff',
				color: '#000000',
				borderColor: '#cccccc',
		  }

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	const lastSeenStyle = colorBasedOnAge(updatedAt)
	const lastSeenDate = formatLastSeenDate(updatedAt)
	const transitDiff = dateDifference(puDate, delDate)

	const equipmentType = equipment === 'POWER ONLY' ? 'PO' : 'VAN'

	useEffect(() => {
		if (hoverPickup) {
			const fetchData = async () => {
				setIsLoading(true)

				const [latitude, longitude] = pickup.location.coordinates
				const response = await loadAnalytics(
					`${latitude}, ${longitude}`,
					equipment
				)

				pickUpSetAnalytics(response?.data)
				setIsLoading(false)
			}

			fetchData()
		}
	}, [hoverPickup])

	useEffect(() => {
		if (hoverDropoff) {
			const fetchData = async () => {
				setIsLoading(true)
				const [latitude, longitude] = dropOff.location.coordinates

				const response = await loadAnalytics(
					`${latitude}, ${longitude}`,
					equipment
				)

				dropOffSetAnalytics(response?.data)
				setIsLoading(false)
			}

			fetchData()
		}
	}, [hoverDropoff])

	const pickupTooltipMessage = isLoading
		? `<div>Loading...</div>`
		: `
			<div style="opacity: 110;"> 
			<h3>Last 24 hours in ${pickupCity}:</h3>
			<ul>
				<li>${pickUpAnalytics?.pickupsCount || 0} pickups</li>
				<li>${pickUpAnalytics?.deliveriesCount || 0} deliveries</li>
				<li>${pickUpAnalytics?.truckCount || 0} truck count</li>
			</ul>
		</div>`

	const dropoffTooltipMessage = isLoading
		? `<div>Loading...</div>`
		: `
  <div>
    <h3>Last 24 hours in ${deliveryCity}:</h3>
    <ul>
      <li>${dropOffAnalytics?.pickupsCount || 0} pickups</li>
      <li>${dropOffAnalytics?.deliveriesCount || 0} deliveries</li>
      <li>${dropOffAnalytics?.truckCount || 0} truck count</li>
    </ul>
  </div>`

	const createdAtDate = formatLastSeenDate(createdAt)
	const updatedAtDate = formatLastSeenDate(updatedAt)

	const loadLastSeenMessage = `
		<div>
			<h3>Updated: ${updatedAtDate} ago</h3>
			
			<h3>Created: ${createdAtDate} ago</h3>
		</div>`

	return (
		<div
			className="px-9 py-1"
			style={{ backgroundColor: isDarkMode ? '#1c2128' : '#f5f5f7' }}
		>
			<div
				onClick={toggleCollapse}
				className={`max-w-screen-xl mx-auto border shadow-sm grid grid-cols-12 gap-4 p-3 rounded-md cursor-pointer transition-all`}
				style={styles}
			>
				<div className="col-span-2 flex items-center">
					<span className="badge badge-accent badge-md mr-2">1</span>

					{/* {hoverPickup && (
							<CityTooltip
								analytics={pickUpAnalytics}
								address={pickup?.address}
							/>
						)} */}

					<div>
						<p
							data-tooltip-delay-show="800"
							data-tooltip-place="top"
							data-tooltip-id={load._id}
							data-tooltip-html={pickupTooltipMessage}
							onMouseOver={() => setHoverPickup(true)}
							onMouseOut={() => setHoverPickup(false)}
							className="text-sm"
						>
							{pickupCity}
						</p>
						<p className="text-gray-500 text-xs">{pickupTime}</p>
					</div>
				</div>

				<div className="col-span-1 mt-3">
					<ArrowDirection load={load} />
				</div>

				<div className="col-span-2 flex items-center">
					<span className="badge badge-accent badge-md mr-2">{loadStops}</span>

					{/* {hoverDropoff && (
						<CityTooltip
							analytics={dropOffAnalytics}
							address={dropOff?.address}
						/>
					)} */}
					<div>
						<p
							data-tooltip-delay-show="800"
							data-tooltip-place="top"
							data-tooltip-id={load._id}
							data-tooltip-html={dropoffTooltipMessage}
							onMouseOver={() => setHoverDropoff(true)}
							onMouseOut={() => setHoverDropoff(false)}
							className="text-sm"
						>
							{deliveryCity}
						</p>
						<p className="text-gray-500 text-xs">{deliveryDate}</p>
					</div>
				</div>

				<div className="col-span-1">
					{/* <p style={lastSeenStyle} className={`text-xs}`}>
						{lastSeenDate}
					</p> */}

					<p
						style={lastSeenStyle}
						data-tooltip-delay-show="800"
						data-tooltip-place="top"
						data-tooltip-id={load._id}
						data-tooltip-html={loadLastSeenMessage}
					>
						{lastSeenDate}
					</p>
					<p className="text-gray-500 text-xs">Last seen</p>
				</div>

				<div className="col-span-1">
					<p className="text-sm">{weight} lb</p>
					<p className="text-gray-500 text-xs">weight</p>
				</div>

				<div className="col-span-1">
					<p className="text-sm">{distance ? distance?.toFixed(0) : 0} mi</p>
					<p className="text-gray-500 text-xs">{transitDiff}</p>
					{/* <p className="text-gray-500 text-xs"></p> */}
				</div>

				<div className="col-span-1">
					<p className="text-sm">{equipmentType}</p>
					<p className="text-gray-500 text-xs">equipment</p>
				</div>

				<div className="col-span-1">
					<p className="text-sm">${price ? price?.toFixed(2) : 0}</p>
					<p className="text-gray-500 text-xs">
						${pricePerMile ? pricePerMile?.toFixed(2) : 0}/mi
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-sm">{source}</p>
					<p className="text-gray-500 text-xs">Source</p>
				</div>

				<div className="col-span-1">
					<p className="text-sm">{truncateString(companyName, 8) || '-'}</p>
					<p className="text-gray-500 text-xs">Broker</p>
				</div>
			</div>

			<Tooltip id={load._id} />

			{/* Collapsible Content */}
			{!isCollapsed && (
				<LoadCollapse load={load} isDarkMode={isDarkMode} styles={styles} />
			)}
		</div>
	)
}
